import { Storage } from '@/components/storage';
import config, { ConfigKey } from '@/config';

/** Plataformas compatíveis com o HUB */
export type PlatformsHub = 'tray' | 'bagy';

export class UrlResolver {
  /**
   * Obtém a plataforma a carregar de acordo com os parâmetros da URL.
   *
   * @param params - Parâmetros da URL.
   */
  static getQueryPlatformByParams(params: URLSearchParams | Record<string, string>): PlatformsHub {
    if (params instanceof URLSearchParams === false) {
      params = new URLSearchParams(params);
    }

    const platform = (params as URLSearchParams).get('platform');
    if (!platform) return 'tray';

    const currentPlatform = platform.toLowerCase() as PlatformsHub;
    return currentPlatform;
  }

  /**
   * Obtém a URL de autenticação de acordo com a plataforma.
   *
   * Usado para os dois fluxos de inicialização do HUB o `/init` e o `/ativar-marketplace`
   */
  static getAuthUrl(): string {
    const queryString = `?${new URLSearchParams(Storage.get('paramsInitHub')).toString()}`;
    const query = location.search || queryString;
    const params = new URLSearchParams(query);
    const { pathname } = window.location;

    const paramsToRemoveOfUrl = [
      'store',
      'adm_user',
      'user_id',
      'store_plan_id',
      'url',
      'marketplace',
      'active_theme', // TODO: quando for alterado o uso do guard de tema ativo, ele deve ser removido dessa lista para carregar no main.vue
    ];
    const queyParamsToURL = new URLSearchParams();

    params.forEach((value, key) => {
      if (!paramsToRemoveOfUrl.includes(key)) {
        queyParamsToURL.append(key, value);
      }
    });
    const currentPlatform = this.getQueryPlatformByParams(params);

    const env: ConfigKey = `${currentPlatform}IntegrationUrl`;
    const platformIntegrationUrl = config(env)?.replace(/\/+$/, '');
    const appUrl = config('appUrl');

    let redirectTo = `${appUrl}/auth?${queyParamsToURL.toString()}`;

    if (pathname !== '/init' && pathname !== '/lets-start') {
      const marketplace = params.get('marketplace');
      redirectTo = `${appUrl}/marketplaces/${marketplace || 'shopee'}/offers?${queyParamsToURL.toString()}`;
    }

    return `${platformIntegrationUrl}/login${query}&redirect_to=${encodeURIComponent(redirectTo)}`;
  }
}
